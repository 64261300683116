/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

.font-size-20 {
  font-size: 20px;
}

.color-danger {
  color: red !important;
}

.color-darkcyan {
  color: darkcyan !important;
}

.color-cyan {
  color: cyan !important;
}

.color-success {
  color: darkgreen !important;
}

.color-primary {
  color: blue !important;
}

.card-modified {
  margin-bottom: 40px;
  border: 0;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 40px;
  box-shadow: 0 0 2px 1px #e0e0e0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.display-hidden {
  display: none !important;
}

.f_10 {
  font-size: 10px !important;
}

.f_20 {
  font-size: 20px !important;
}

.ml-5px {
  margin-left: 5px !important;
}

.p-25px {
  padding: 25px !important;
}

.search-card {
  background-color: #fff;
  box-shadow: 0 2px 24px 0 #c7d9ff;
  padding-bottom: 15px;
  border: 2px solid #e2e4ea;
  margin: 15px 0 20px;
  border-radius: 15px;
}

.mt-15px {
  margin-top: 15px !important;
}

.mb--15px {
  margin-bottom: -15px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

tr:hover {
  background-color: #f9f9f9
}

.width-webkit-fill-available {
  width: -webkit-fill-available !important;
}

.p-60px {
  padding: 60px !important;
}
